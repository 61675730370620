var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('PageHeaderDisplay', {
    attrs: {
      "title": "Integrations",
      "sub-title": "Manage everything to do with automation tasks.",
      "category": "Automation",
      "category-logo": "fa-conveyor-belt",
      "category-route": {
        name: _vm.$route.name
      }
    }
  }), _c('TabView', {
    attrs: {
      "route-name": "automation",
      "tabs": _vm.tabs
    }
  }, [_c('router-view')], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }